<template>
  <div>
    <header-slot />
    <b-nav card-header pills class="m-0">
      <b-nav-item
        :to="{ name: 'customerservice-chat-revision-pending' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
      >
        Pending
      </b-nav-item>
      <b-nav-item
        :to="{ name: 'customerservice-chat-revision-accepted' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
      >
        Accepted
      </b-nav-item>
      <b-nav-item
        :to="{ name: 'customerservice-chat-revision-rejected' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
      >
        Rejected
      </b-nav-item>
    </b-nav>
    <b-card no-body class="border-top-primary border-3 border-table-radius">
      <router-view :key="$route.name" />
    </b-card>
  </div>
</template>

<script>
export default {
};
</script>